import React, { Component } from 'react';
import { UncontrolledTooltip, Card, CardHeader, CardTitle, CardBody, CardFooter, Table, Pagination, PaginationItem, PaginationLink, Spinner, UncontrolledDropdown, DropdownToggle, Button, ButtonGroup, Badge } from 'reactstrap';
import moment from 'moment';

export class EventsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCollapse: false,
            currentPage: 0,
            showPages: 10
        };
    }

    handleViewRequestClick = (file) => {
        this.props.onViewRequestClick(file);
    }

    handlePrintButtonClick = (file) => {
        this.props.onPrintButtonClick(file);
    }

    handlePageClick = (e, index) => {
        e.preventDefault();
        if (index >= 0 && index < this.props.pageCount) {
            this.setState({
                currentPage: index
            });
        }
    };

    render() {
        var pageNumbers = [];
        var diff = Math.floor(this.state.showPages / 2),
            start = Math.max(this.state.currentPage - diff, 0),
            end = Math.min(start + this.state.showPages, this.props.pageCount);

        if (this.props.pageCount >= this.state.showPages && end >= this.props.pageCount) {
            start = this.props.pageCount - this.state.showPages;
        }

        for (var i = start; i < end; i++) {
            pageNumbers.push(i);
        }

        const { settings } = this.props;
        let normalizedAddress = false;
        if (settings && settings.configuration)
            normalizedAddress = settings.configuration[0].Enabled;

        return (
            <Card className="card-chart">
                <CardBody>
                    {this.props.loading ?
                        <Spinner>
                            Loading...
                        </Spinner> : <>
                            <Table
                                hover
                                striped
                                bordered
                            >
                                <thead>
                                    <tr>
                                        <th>Reference</th>
                                        <th>Carrier</th>
                                        <th>Shipment Date</th>
                                        <th>Consignment</th>
                                        <th>Company Name</th>
                                        <th>Postcode</th>
                                        <th>Service</th>
                                        <th>Packages</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.documents.slice(this.state.currentPage * this.props.pageSize, (this.state.currentPage + 1) * this.props.pageSize).map(file => {
                                        if (file.requestShipment && file.responseShipment)
                                            return (
                                                <tr className={file.requestResult == 200 ? "table-success" : file.requestResult == 202 ? "table-warning" : "table-danger"} style={{ cursor: 'pointer' }}>
                                                    <td>{file.requestShipment.sourceShipmentId}</td>
                                                    <td>{file.requestShipment.carrier.carrierCode}</td>
                                                    <td>{moment(file.requestDate).format("yyyy/MM/DD HH:mm:ss")}</td>
                                                    <td>{file.responseShipment.carrierConsignmentId || file.requestShipment.consignmentNumber}</td>
                                                    <td>{file.requestShipment.receiver.address.companyName}</td>
                                                    <td>{normalizedAddress ? file.requestShipment.receiver.address.postCode : file.requestShipment.receiver.address.postalCode}</td>
                                                    <td>{file.requestShipment.carrier.serviceGroup}</td>
                                                    <td>{file.requestShipment.shipmentPackages.length}</td>
                                                    <td>
                                                        {file.requestResult == 200 ?
                                                            <Badge color="success" pill>
                                                                <i className="fas fa-check mr-2"></i> {file.requestStatus.toUpperCase()}
                                                            </Badge> :
                                                            file.requestResult == 202 ?
                                                                <Badge color="warning" pill>
                                                                    <i className="fas fa-pause mr-2"></i> {file.requestStatus.toUpperCase()}
                                                                </Badge>
                                                                :
                                                                <Badge color="danger" pill>
                                                                    <i className="fas fa-ban mr-2"></i> {file.requestStatus.toUpperCase()}
                                                                </Badge>
                                                        }
                                                    </td>
                                                    <td className="text-right">
                                                        <ButtonGroup>
                                                            <Button id="view" onClick={e => this.handleViewRequestClick(file)} style={{ padding: '8px', border: 'none !important' }} color='info'>
                                                                <i className="fas fa-file" />
                                                            </Button>
                                                            <UncontrolledTooltip placement="top-start" target="view">
                                                                Click to view consignment info.
                                                            </UncontrolledTooltip>
                                                            {this.props.printVisible ?

                                                                <Button id="print" onClick={e => this.handlePrintButtonClick(file)} style={{ padding: '8px', border: 'none; !important' }} color='success'>
                                                                    <i className="fas fa-print" />
                                                                </Button>

                                                                : <></>
                                                            }
                                                            {this.props.printVisible ?

                                                                <UncontrolledTooltip placement="top-start" target="print">
                                                                    Click to view print info.
                                                                </UncontrolledTooltip>

                                                                : <></>
                                                            }


                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            )
                                    })}
                                </tbody>
                            </Table>
                            <Pagination style={{ padding: '15px' }}>
                                <PaginationItem>
                                    <PaginationLink
                                        first
                                        onClick={e => this.handlePageClick(e, 0)}
                                    />
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink
                                        onClick={e => this.handlePageClick(e, this.state.currentPage - 1)}
                                        previous
                                    />
                                </PaginationItem>
                                {pageNumbers.map((page) => {
                                    return <PaginationItem active={page === this.state.currentPage} key={page}>
                                        <PaginationLink onClick={e => this.handlePageClick(e, page)}>
                                            {page + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                })}
                                <PaginationItem>
                                    <PaginationLink
                                        onClick={e => this.handlePageClick(e, this.state.currentPage + 1)}
                                        next
                                    />
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink
                                        onClick={e => this.handlePageClick(e, this.props.pageCount - 1)}
                                        last
                                    />
                                </PaginationItem>
                            </Pagination>
                        </>}
                </CardBody>
                <CardFooter>
                </CardFooter>
            </Card>
        )
    }
}