import React, { Component } from 'react';
import { UncontrolledTooltip, InputGroup, Card, CardBody, CardTitle, CardSubtitle, CardText, CardHeader, ButtonGroup, Input, Button, Spinner, CardImg, Row, Col, Badge, Form } from 'reactstrap';
import moment from 'moment';
import { ViewRequestModal } from './modals/ViewRequestModal';
import { withSession } from '../Auth';
import { SubmissionResponseModal } from './modals/SubmissionResponseModal';


class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: "",
            suggestions: [],
            suggestionsToDisplay: [],
            rowsToDisplay: 20,
            loading: false,
            viewRequestModalOpen: false,
            selectedFile: {},
            submissionResponseModalOpen: false,
            submissionResponse: null,
        }

        this.handleSearch = this.handleSearch.bind(this);
        this.handleViewRequestClick = this.handleViewRequestClick.bind(this);
        this.lastElementRef = React.createRef();
    }

    componentDidMount() {
        if (this.lastElementRef.current) {
            this.observer.observe(this.lastElementRef.current);
        }
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
            this.addDataToDisplay(this.state.suggestions);
        }
    });

    submissionResponseModalToggle = () => {
        this.setState({
            submissionResponseModalOpen: !this.state.submissionResponseModalOpen
        });

        if (this.state.submissionResponseModalOpen)
            this.handleSearch()
    }

    viewRequestModalToggle = () => {
        this.setState({ selectedFile: {} });
        this.setState({ viewRequestModalOpen: false });
    }

    handleViewRequestClick = (file) => {
        this.setState({ selectedFile: file });
        this.setState({ viewRequestModalOpen: true });
    }

    displaySubmissionResponse = (response) => {
        this.setState({
            submissionResponseModalOpen: true,
            viewRequestModalOpen: false,
            submissionResponse: response
        })
    }

    handleChange = async (event) => {

        this.setState({ searchTerm: event.target.value })
    }

    handleSearch = async () => {

        try {
            this.setState({ loading: true });
            this.setState({ suggestions: [] });
            this.setState({ suggestionsToDisplay: [] });
            this.setState({ rowsToDisplay: 20 });

            let clientId = this.props.clientId;

            const response = await fetch("reportdata?clientId=" + clientId + "&queryString=" + this.state.searchTerm);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            this.setState({ suggestions: data });
            this.setState({ loading: false });
            this.addDataToDisplay(data);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            this.setState({ suggestions: [] }); // Clear suggestions in case of an error
            this.setState({ suggestionsToDisplay: [] });
            this.setState({ loading: false });
        };
    }

    addDataToDisplay = (data) => {
        var data = data.slice(this.state.suggestionsToDisplay.length, this.state.rowsToDisplay);
        this.setState({
            suggestionsToDisplay: this.state.suggestionsToDisplay.concat(data),
            rowsToDisplay: this.state.rowsToDisplay + 20
        })
    }

    render() {
        return (
            <div>
                <Form onSubmit={this.handleSearch}>
                    <InputGroup>
                        <Input id="searchText"
                            type="text"
                            placeholder="Search consignments..."
                            value={this.state.searchTerm}
                            onChange={this.handleChange}
                        />
                        <UncontrolledTooltip placement="top-start" target="searchText">
                            Please enter a search criteria.
                        </UncontrolledTooltip>

                        <Button type='submit' id="searchButton" disabled={this.state.loading} color="primary" onClick={this.handleSearch}>
                            Search
                        </Button>
                        <UncontrolledTooltip placement="right" target="searchButton">
                            Click here to perform search.
                        </UncontrolledTooltip>

                    </InputGroup>
                </Form>

                <div style={{ overflowY: 'auto', paddingTop: '30px' }}>
                    {this.state.suggestionsToDisplay.map((consignment) => (
                        consignment.requestShipment ?
                            <Card onClick={e => this.handleViewRequestClick(consignment)} style={{ cursor: "pointer" }} key={consignment.id} className="mb-3">
                                <CardBody>
                                    <Row>

                                        <Col xs="4">
                                            <h5><a onClick={e => this.handleViewRequestClick(consignment)} href="javascript:void(0);"> {consignment.requestShipment.receiver.address.companyName || consignment.requestShipment.receiver.contact.fullName  + " - " + consignment.requestShipment.customerReference}</a></h5>
                                            <div>
                                                <time dateTime="2020-05-25 12:00:00">
                                                    <i className="fas fa-calendar-alt mr-2" style={{ marginRight: '5px' }}></i>{moment(consignment.requestDate).format("YYYY-MM-DD HH:mm")}
                                                </time>
                                            </div>
                                            <div class="postcard__bar"></div>
                                            <div class="postcard__preview-txt">
                                                {consignment.requestResult == 200 ?
                                                    <div>Consignment ID: {consignment.responseShipment ? consignment.responseShipment.carrierConsignmentId : "N/A"}</div> :

                                                    <div>Error: {consignment.responseShipment && consignment.responseShipment.errorMessages && consignment.responseShipment.errorMessages.length > 0 ? consignment.responseShipment.errorMessages[0].errorMessage : "Consignment creation failed"}</div>
                                                }

                                            </div>
                                        </Col>
                                        <Col xs="auto">
                                            <i className="fas fa-home-alt mr-2"></i>
                                        </Col>
                                        <Col xs="4">
                                            <Row><div><small>{consignment.requestShipment.receiver.address.addressLine1}</small></div></Row>
                                            <Row><div><small>{consignment.requestShipment.receiver.address.addressLine2}</small></div></Row>
                                            <Row><div><small>{consignment.requestShipment.receiver.address.addressLine3}</small></div></Row>
                                            <Row><div><small>{consignment.requestShipment.receiver.address.city}</small></div></Row>
                                            <Row><div><small>{consignment.requestShipment.receiver.address.county}</small></div></Row>
                                            <Row><div><small>{consignment.requestShipment.receiver.address.postCode}</small></div></Row>
                                            <Row><div><small>{consignment.requestShipment.receiver.address.country}</small></div></Row>
                                        </Col>

                                        <Col>
                                            <div style={{ display: "flex" }}>

                                                <Button id="viewButton" onClick={e => this.handleViewRequestClick(consignment)} style={{ marginLeft: "auto", padding: '8px', border: 'none !important' }} color='info'>
                                                    <i className="fas fa-file" />
                                                </Button>
                                                <UncontrolledTooltip placement="right" target="viewButton">
                                                    View consignment.
                                                </UncontrolledTooltip>

                                            </div>
                                        </Col>
                                    </Row>
                                    <Col>

                                        <Row>
                                            <div>
                                                <Badge color="primary" pill>
                                                    <i className="fas fa-tag mr-2"></i> {consignment.requestShipment.carrier.carrierCode}
                                                </Badge>
                                                <span> </span>
                                                {consignment.requestResult == 200 ?
                                                    <Badge color="success" pill>
                                                        <i className="fas fa-check mr-2"></i> Success
                                                    </Badge> :
                                                    <Badge color="danger" pill>
                                                        <i className="fas fa-ban mr-2"></i> Error
                                                    </Badge>}
                                            </div>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card>
                            :
                            <></>
                    ))}

                    <div ref={this.lastElementRef} style={{ padding: '10px', textAlign: 'center' }}>
                        {this.state.loading ?
                            <Spinner>
                                Loading...
                            </Spinner>
                            :
                            <div />
                        }
                    </div>

                </div>
                {this.state.viewRequestModalOpen &&
                    <ViewRequestModal
                        isOpen={this.state.viewRequestModalOpen}
                        toggle={this.viewRequestModalToggle}
                        file={this.state.selectedFile}
                        settings={this.props.settings}
                        displaySubmissionResponse={this.displaySubmissionResponse}
                    />
                }
                {this.state.submissionResponseModalOpen &&
                    <SubmissionResponseModal
                        isOpen={this.state.submissionResponseModalOpen}
                        toggle={this.submissionResponseModalToggle}
                        response={this.state.submissionResponse}
                    />
                }
            </div>

        );
    }
};

export default withSession(Search);
