import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { withAuth } from "react-oidc-context";

class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.state = {
      collapsed: true
    };
  }

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  logout = () => {
    localStorage.clear();
    void this.props.auth.signoutRedirect()
  }

  render() {
    const { auth } = this.props;

    if (auth.isLoading) {
      return <div>Loading...</div>;
    }

    if (auth.error) {
      return <div>Oops... {auth.error.message}</div>;
    }

    if (auth.isAuthenticated)
      return (
        <header>
          <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
            {/* <NavbarBrand tag={Link} to="/">Carrier Connect Dashboard</NavbarBrand> */}
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <a className="text-dark" onClick={() => this.logout()} >{auth.user.profile.name}</a>
                </NavItem>
              </ul>
            </Collapse>
          </Navbar>
        </header>
      );
  }
}

export default withAuth(NavMenu);