import Home from "./components/Home";
import Reports from './components/Reports';
import Search from "./components/Search";

const AppRoutes = [
  {
    index: true,
    name: "Home",
    element: <Home />,
    icon: "fa fa-house",
    path: "/"
  },
  {
    index: false,
    name: "Search",
    element: <Search />,
    icon: "fa fa-search",
    path: "/Search"
  },
  {
    index: false,
    name: "Reports",
    element: <Reports />,
    icon: "fa fa-file-contract",
    path: "/Reports"
  }
];

export default AppRoutes;
