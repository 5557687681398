import React, { Component } from 'react';
import { UncontrolledTooltip, FormFeedback, Input, Form, FormGroup, Label, Button, Card, CardBody, Col, Row, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { EventsTable } from './EventsTable';
import moment from 'moment';
import queryString from 'stringquery';
import { ViewRequestModal } from './modals/ViewRequestModal';
import { ViewLabelModal } from './modals/ViewLabelModal';

import { ProcessReportModal } from './modals/ProcessReportModal';
import { withAuth } from 'react-oidc-context';
import { withSession } from '../Auth';

class Reports extends Component {
    static displayName = Reports.name;

    constructor(props) {
        super(props);

        this.state = {
            collapse: true,
            data: [],
            documents: [],
            pageSize: 10,
            loading: false,
            pageCount: 0,
            rSelected: 1,
            filterText: 'This Week',
            sucessCount: 0,
            failedCount: 0,
            parsed: queryString(window.location.search),
            carrier: 'ALL',
            dropDownOpen: false,
            dropDownValue: '',
            toDate: moment().format("yyyy-MM-DD"),
            fromDate: moment().format("yyyy-MM-DD"),
            toDateInvalid: false,
            fromDateInvalid: false,
            filterChanged: true,
            viewRequestModalOpen: false,
            selectedRequest: null,
            displayLabelModalOpen: false,
            parsed: queryString(window.location.search),
            requestStatus: 'SUCCESS',
            requestStatusDropDownOpen: false,
            collectionSite: 'ALL',
            collectionSiteDropDownOpen: false,
            defaultReportDropDownOpen: false,
            processReport: false,
            displayProcessReportModalOpen: false
        };

        this.toggle = this.toggle.bind(this);
        this.toggleCarrier = this.toggleCarrier.bind(this);
        this.toggleRequestStatus = this.toggleRequestStatus.bind(this);
        this.handleCarrierChange = this.handleCarrierChange.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleRequestStatusChange = this.handleRequestStatusChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.validateToDateFilterClause = this.validateToDateFilterClause.bind(this);
        this.validateFromDateFilterClause = this.validateFromDateFilterClause.bind(this);

        this.toggleCollectionSite = this.toggleCollectionSite.bind(this);
        this.handleCollectionSiteChange = this.handleCollectionSiteChange.bind(this);

        this.toggleDefaultReport = this.toggleDefaultReport.bind(this);
        this.handleDefaultReportChange = this.handleDefaultReportChange.bind(this);

        this.processReport = this.processReport.bind(this);

        //this.viewProcessReportModalToggle = this.this.viewProcessReportModalToggle.bind(this);

    }




    componentDidMount() {
        const fromDate = moment().format("yyyy-MM-DD");
        const toDate = moment().format("yyyy-MM-DD 23:59:59");
        //this.populateData(fromDate, toDate, 'ALL', 'ALL', 24, 'hA');
    }

    viewRequestModalToggle = (file) => {
        this.setState({
            viewRequestModalOpen: !this.state.viewRequestModalOpen,
            selectedRequest: file
        });
    }

    viewLabelModalToggle = (file) => {
        this.setState({
            displayLabelModalOpen: !this.state.displayLabelModalOpen,
            selectedRequest: file
        });
    }

    viewProcessReportModalToggle = () => {
        this.setState({
            displayProcessReportModalOpen: !this.state.displayProcessReportModalOpen
        });
    }




    populateData = async (fromDate, toDate, carrierCode, status, deliveryCountry) => {
        this.setState({ loading: true });
        //let clientId = this.state.parsed.clientId;
        let clientId = this.props.clientId;

        if (!clientId)
            clientId = "FLO02";

        if (!carrierCode)
            carrierCode = "ALL";

        if (!status)
            status = "ALL";

        const response = await fetch(`request?clientId=${clientId}&fromDateTime=${fromDate}&toDateTime=${toDate}&carrierCode=${carrierCode}&status=${status}&deliveryCountry=${deliveryCountry}`); //
        const data = await response.json();
        //var rows;
        //if (rowCount === 24) {
        //    rows = this.filterData(data, fromDate, rowCount, displayType, 'hour');
        //}
        //else {
        //    rows = this.filterData(data, fromDate, rowCount, displayType, 'day');
        //}

        const noPages = Math.ceil(data.length / 10);

        this.setState({ data: data, documents: data, loading: false, pageCount: noPages });
    }

    filterData = (data, startdate, rowCount, displayType, timeType) => {
        var sucesscount = [];
        var failedcount = [];
        var labels = [];
        var tableRows = [];
        const day = moment(startdate);

        for (var i = 0; i < rowCount; i++) {
            const items = data.filter(row => moment(row.requestDate).isSame(day, timeType));
            sucesscount.push(items.filter(row => row.requestResult == 200).length);
            failedcount.push(items.filter(row => row.requestResult != 200).length);
            labels.push(day.format(displayType));
            tableRows = tableRows.concat(items);
            day.add(1, timeType);
        }

        this.setState({
            sucessCount: sucesscount.reduce((r, c) => r + c, 0),
            failedCount: failedcount.reduce((r, c) => r + c, 0),
        })

        return tableRows;
    }

    applyFilter = async () => {

        let toDate;
        if (!this.state.toDate) {
            toDate = moment().format("yyyy-MM-DD 23:59:59");
        }
        else {
            toDate = moment(this.state.toDate).format("yyyy-MM-DD 23:59:59");
        }

        let fromDate;
        if (!this.state.fromDate) {
            fromDate = moment().format("yyyy-MM-DD");
        }
        else {
            fromDate = moment(this.state.fromDate).format("yyyy-MM-DD");
        }

        this.setState({
            rSelected: -1,
            filterChanged: false,
            collapse: false,
            processReport: true
        });

        await this.populateData(fromDate, toDate, this.state.carrier, this.state.requestStatus, this.state.collectionSite);

    }

    clearFilter = async () => {

        //run the 'today query'
        const fromDate = moment().format("yyyy-MM-DD");
        const toDate = moment().format("yyyy-MM-DD 23:59:59");
        await this.populateData(fromDate, toDate, 'ALL', 'ALL', 24, 'hA');

        this.setState({
            carrier: "ALL",
            fromDate: "",
            toDate: "",
            filterChanged: false,
            toDateInvalid: false,
            fromDateInvalid: false,
            requestStatus: "ALL",
            collectionSite: "ALL",
            processReport: false
        });
    }

    toggle() {
        this.setState({
            collapse: !this.state.collapse
        })
    }

    toggleCarrier() {
        this.setState({
            dropDownOpen: !this.state.dropDownOpen,
        })
    }

    toggleRequestStatus() {
        this.setState({
            requestStatusDropDownOpen: !this.state.requestStatusDropDownOpen,
        })
    }

    toggleCollectionSite() {
        this.setState({
            collectionSiteDropDownOpen: !this.state.collectionSiteDropDownOpen
        })
    }

    toggleDefaultReport() {
        this.setState({
            defaultReportDropDownOpen: !this.state.defaultReportDropDownOpen
        })
    }

    handleDefaultReportChange(report) {
        //currently just an example of what we might do.
        //but essentially default the state values and 
        //run the 'today query'
        const fromDate = moment().format("yyyy-MM-DD");
        const toDate = moment().format("yyyy-MM-DD");

        this.setState({
            carrier: "GLS",
            fromDate: fromDate,
            toDate: toDate,
            filterChanged: false,
            toDateInvalid: false,
            fromDateInvalid: false,
            requestStatus: "SUCCESS",
            collectionSite: "SITE2"

        });

        this.applyFilter();
    }

    handleRequestStatusChange(status) {
        if (status && status.target && status.target.textContent) {
            if (this.state.requestStatus !== status.target.textContent) {
                this.setState({ requestStatus: status.target.textContent });
                this.setState({ filterChanged: true, processReport: false })
            }
        }
    }

    handleCollectionSiteChange(site) {
        if (site && site.target && site.target.textContent) {
            if (this.state.collectionSite !== site.target.textContent) {
                this.setState({ collectionSite: site.target.textContent });
                this.setState({ filterChanged: true, processReport: false })
            }
        }
    }

    handleCarrierChange(code) {
        if (code && code.target && code.target.textContent) {
            if (this.state.carrier !== code.target.textContent) {
                this.setState({ carrier: code.target.textContent });
                this.setState({ filterChanged: true, processReport: false })
            }


        }
    }

    handleFromDateChange(e) {
        const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');

        if (this.state.fromDate !== newDate) {
            this.setState({ fromDate: newDate });
            if (this.validateFromDateFilterClause(newDate)) {
                this.setState({ fromDateInvalid: false });
                this.setState({ filterChanged: true, processReport: false })
            }
            else {
                this.setState({ filterChanged: false });
            }
        }
    }

    handleToDateChange(e) {
        const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');

        if (this.state.toDate !== newDate) {
            this.setState({ toDate: newDate });
            if (this.validateToDateFilterClause(newDate)) {
                this.setState({ filterChanged: true, processReport: false })
                this.setState({ toDateInvalid: false });
            }

            else {

                this.setState({ filterChanged: false });
            }
        }
    }

    validateToDateFilterClause(date) {
        if ((!this.state.fromDate || this.state.fromDate === 'Invalid date') && date) {
            //need to set a from date
            this.setState({ fromDateInvalid: true });
            return false;
        }
        else if (!date && this.state.fromDate) {
            this.setState({ toDateInvalid: true });
            return false;
        }
        else if (moment(this.state.fromDate) > moment(date)) {
            this.setState({ toDateInvalid: true });
            return false;
        }
        else {
            return true;
        }
    }

    validateFromDateFilterClause(date) {
        if (!date && this.state.toDate) {
            this.setState({ toDateInvalid: true });
            //need to set a to date
            return false;
        }
        else if (!this.state.toDate && date) {
            this.setState({ toDateInvalid: true });
            return false;
        }
        else if (moment(date) > moment(this.state.toDate)) {
            this.setState({ fromDateInvalid: true });
            return false;
        }
        else {
            return true;
        }
    }

    processReport() {
        this.setState({
            displayProcessReportModalOpen: !this.state.displayProcessReportModalOpen
        });
    }


    render() {
        const { auth } = this.props;
        var message = "Please login to continue";

        switch (auth.activeNavigator) {
            case "signinSilent":
                message = "Signing you in...";
            case "signoutRedirect":
                message = "Signing you out...";
        }

        if (auth.isLoading) {
            message = "Loading...";
        }

        if (auth.error) {
            message = `Error: ${auth.error.message}`;
        }

        return (
            <div className="content">
                {!auth.isAuthenticated ?
                    <Col>
                        <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                            <p className="h5">{message}</p>
                        </Row>
                        <Label for="clientDropDown">
                            Client
                        </Label>
                        <Dropdown id="clientDropDown" isOpen={this.state.dropDownOpen} toggle={this.toggleCarrier}>
                            <DropdownMenu>
                                <DropdownItem onClick={this.handleCarrierChange}>DAT01</DropdownItem>
                                <DropdownItem onClick={this.handleCarrierChange}>FLO01</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Row>
                            <Col>
                                <Button id="login" color="primary" onClick={() => void auth.signinRedirect()} style={{ marginBottom: '1rem' }}>
                                    Login
                                </Button>
                                <UncontrolledTooltip placement="right" target="login">
                                    Click to login.
                                </UncontrolledTooltip>
                            </Col>
                        </Row>
                    </Col>
                    :
                    <>
                        <div className="d-flex" style={{ gap: '4px' }}>
                            <Button id="filter" color="primary" onClick={this.toggle} style={{ marginBottom: '1rem' }}>
                                <i className={this.state.collapse ? 'fa-solid fa-square-caret-up' : 'fa-solid fa-square-caret-down'} /> Filter
                            </Button>
                            <UncontrolledTooltip placement="right" target="filter">
                                Show/hide filter criteria.
                            </UncontrolledTooltip>
                            <Button id="process" disabled={!this.state.processReport} onClick={() => this.processReport()} style={{ marginBottom: '1rem' }}>
                                Process Report
                            </Button>
                            <UncontrolledTooltip placement="right" target="process">
                                Click to process a manifest report.
                            </UncontrolledTooltip>
                        </div>

                        <Row>
                            <Collapse isOpen={this.state.collapse}>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <FormGroup row>
                                                <Col sm={10}>
                                                    <Label for="carrierDropDown">
                                                        Carrier Code
                                                    </Label>

                                                    <Dropdown id="carrierDropDown" isOpen={this.state.dropDownOpen} toggle={this.toggleCarrier}>
                                                        <DropdownToggle caret>
                                                            {this.state.carrier}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem key="ALL" onClick={this.handleCarrierChange}>ALL</DropdownItem>
                                                            {(this.props.settings !== null && this.props.settings !== undefined) && (this.props.settings.carriers !== null && this.props.settings.carriers !== undefined) ? (
                                                                this.props.settings.carriers.split(',').map((item, index) => (<DropdownItem key={item} onClick={this.handleCarrierChange}>{item}</DropdownItem>))
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                    <UncontrolledTooltip placement="left-start" target="carrierDropDown">
                                                        Select a carrier to filter by.
                                                    </UncontrolledTooltip>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col sm={10}>
                                                    <Label for="requestStatus">
                                                        Request Status
                                                    </Label>
                                                    <Dropdown id="requestStatusDropDown" isOpen={this.state.requestStatusDropDownOpen} toggle={this.toggleRequestStatus}>
                                                        <DropdownToggle caret>
                                                            {this.state.requestStatus}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={this.handleRequestStatusChange}>ALL</DropdownItem>
                                                            <DropdownItem onClick={this.handleRequestStatusChange}>SUCCESS</DropdownItem>
                                                            <DropdownItem onClick={this.handleRequestStatusChange}>ERROR</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                    <UncontrolledTooltip placement="left-start" target="requestStatusDropDown">
                                                        Select a requests status to filter by.
                                                    </UncontrolledTooltip>
                                                </Col>
                                            </FormGroup>
                                            {((this.props.settings !== null && this.props.settings !== undefined) && (this.props.settings.countries !== null && this.props.settings.countries !== undefined)) ?
                                                <FormGroup row>
                                                    <Col sm={10}>
                                                        <Label for="collectionSite">
                                                            Delivery Country Code
                                                        </Label>
                                                        <Dropdown id="collectionSiteDropDown" isOpen={this.state.collectionSiteDropDownOpen} toggle={this.toggleCollectionSite}>
                                                            <DropdownToggle caret>
                                                                {this.state.collectionSite}
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem onClick={this.handleCollectionSiteChange}>ALL</DropdownItem>

                                                                {(this.props.settings !== null && this.props.settings !== undefined) && (this.props.settings.countries !== null && this.props.settings.countries !== undefined)
                                                                    ? (this.props.settings.countries.split(',').map((item, index) => (<DropdownItem key={item} onClick={this.handleCollectionSiteChange}>{item}</DropdownItem>)))
                                                                    : (<></>)
                                                                }
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                        <UncontrolledTooltip placement="left-start" target="collectionSiteDropDown">
                                                            Select a delivery country to filter by.
                                                        </UncontrolledTooltip>
                                                    </Col>
                                                </FormGroup>
                                                :<></>
                                            }
                                            
                                            <FormGroup row>
                                                <Col sm={10}>
                                                    <Label for="fromDate">
                                                        From Date
                                                    </Label>
                                                    <Input
                                                        id="fromDate"
                                                        name="fromDate"
                                                        onChange={this.handleFromDateChange}
                                                        placeholder="date placeholder"
                                                        value={this.state.fromDate}
                                                        type="date"
                                                        invalid={!!this.state.fromDateInvalid} />
                                                    <UncontrolledTooltip placement="right" target="fromDate">
                                                        Filter by from date.
                                                    </UncontrolledTooltip>
                                                    <Label for="toDate">
                                                        To Date
                                                    </Label>
                                                    <Input
                                                        id="toDate"
                                                        name="toDate"
                                                        onChange={this.handleToDateChange}
                                                        placeholder="date placeholder"
                                                        value={this.state.toDate}
                                                        type="date"
                                                        invalid={!!this.state.toDateInvalid} />
                                                    <UncontrolledTooltip placement="right" target="toDate">
                                                        Filter by to date.
                                                    </UncontrolledTooltip>
                                                    <FormFeedback>
                                                        Please enter a valid date range
                                                    </FormFeedback>
                                                </Col>
                                            </FormGroup>
                                            {/*<FormGroup>*/}
                                            {/*    <Col sm={10}>*/}
                                            {/*        <Label check>*/}
                                            {/*            Un-Despatched Only*/}
                                            {/*        </Label>*/}
                                            {/*        <span> </span>*/}
                                            {/*        <Input type="checkbox" />*/}
                                            {/*    </Col>*/}
                                            {/*</FormGroup>*/}
                                            <FormGroup>
                                                <Col sm={10}>
                                                    <Button id="clear" onClick={() => {
                                                        return this.clearFilter();
                                                    }} color="danger">Clear</Button>
                                                    <UncontrolledTooltip placement="right" target="clear">
                                                        Clear filter.
                                                    </UncontrolledTooltip>
                                                    <span> </span>
                                                    <Button id="apply" disabled={!this.state.filterChanged} onClick={() => this.applyFilter()} color="success">Apply</Button>
                                                    <UncontrolledTooltip placement="right" target="apply">
                                                        Apply filter.
                                                    </UncontrolledTooltip>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Row>

                        <EventsTable
                            loading={this.state.loading}
                            documents={this.state.documents}
                            pageCount={this.state.pageCount}
                            pageSize={this.state.pageSize}
                            onViewRequestClick={this.viewRequestModalToggle}
                            printVisible={false}
                            settings={this.props.settings}
                        />
                    </>}
                {this.state.viewRequestModalOpen &&
                    <ViewRequestModal
                        isOpen={this.state.viewRequestModalOpen}
                        toggle={this.viewRequestModalToggle}
                        file={this.state.selectedRequest}
                        settings={this.props.settings}
                    />
                }
                {this.state.displayLabelModalOpen &&
                    <ViewLabelModal
                        isOpen={this.state.displayLabelModalOpen}
                        toggle={this.viewLabelModalToggle}
                        file={this.state.selectedRequest}
                    />
                }

                {this.state.displayProcessReportModalOpen &&
                    <ProcessReportModal
                    isOpen={this.state.displayProcessReportModalOpen}
                    toggle={this.viewProcessReportModalToggle}
                    report_data={this.state.data}
                    carrier={this.state.carrier}
                    date_from={this.state.fromDate}
                    date_to={this.state.toDate}
                    clientId={this.props.clientId}
                    />
                }
            </div>
        );
    }
}

export default withSession(Reports);