import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Col, Row, Container, Label } from 'reactstrap';
import moment from 'moment';
import { PackageTable } from 'components/PackageTable';
import { DocumentViewer } from 'components/DocumentViewer';

export class ViewLabelModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            labelDisplay: false,
            labelData: ''
        };
    }

    componentDidMount = async () => {
        const request = this.props.file.requestShipment;
        const response = this.props.file.responseShipment;
        let labelDisplay = false;
        let labelData;

        if (response.labels.length > 0)
            labelData = response.labels[0].labelImage;

        if (request.labels.labelType == 'PDF')
            labelDisplay = true;
        else if (request.labels.labelType == 'ZPL')
            this.convertToPDF(labelData)

        this.setState({
            labelDisplay,
            labelData
        })
    }

    convertToPDF = async (zpl) => {
        try {
            const response = await fetch(`label?zpl=${zpl}`);
            const data = await response.json();

            this.setState({
                labelData: data.labelData,
                labelDisplay: true
            })
        } catch (err) {
            console.log(err)
        }
    }


    downloadPdf = (shipmentId, base64String) => {
        // Convert base64 to Blob
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `${shipmentId}.pdf`); // Name the file

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        URL.revokeObjectURL(blobUrl);
    };

    render() {
        const request = this.props.file.requestShipment;
        const response = this.props.file.responseShipment;
        const { labelDisplay, labelData } = this.state;

        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} style={{ maxWidth: '80%' }}>
                <ModalHeader toggle={this.props.toggle}>{'Label: ' + request.sourceShipmentId}</ModalHeader>
                <ModalBody>
                    {labelDisplay ?
                        <DocumentViewer data={'data:application/pdf;base64,' + labelData} page={1} />
                        :
                        <Label>Document type not supported in viewer</Label>
                    }
                </ModalBody>
                <ModalFooter>
                    {labelData &&
                        <Button color="info" onClick={() => this.downloadPdf(request.sourceShipmentId, labelData)}>
                            <i style={{ marginRight: '5px' }} className="fas fa-file-arrow-down"></i> Download Label
                        </Button>
                    }
                    <Button color="secondary" onClick={this.props.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}