import React, { Component } from 'react';
import {pdfjs, Document, Page } from 'react-pdf';
import { Spinner } from 'reactstrap';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

export class DocumentViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numPages: 0
        }
    }

    onDocumentLoadSuccess = (pdf) => {
        console.log(pdf)
    }

    onDocumentLoadError = (error) => {
        console.error(error)
    }

    render() {
        return (
            <div>
                {this.props.data ?
                    <Document file={this.props.data} onLoadSuccess={this.onDocumentLoadSuccess} onLoadError={this.onDocumentLoadError}>
                        <Page renderTextLayer={false} renderAnnotationLayer={false} pageNumber={this.props.page} />
                    </Document>
                    :
                    <Spinner />
                }
            </div>
        );
    }
}